<template>
  <section class="contact-us py-4 py-sm-6">
    <div class="bs-container">
      <v-card class="bs-shadow-none">
        <div class="pa-6 pa-md-12">
          <ContactUs />
        </div>
      </v-card>
    </div>
  </section>
</template>

<style scoped>
.contact-us p:last-of-type {
  margin-bottom: 0 !important;
}
</style>

<script>
import ContactUs from "@/components/ContactUs.vue";
export default {
  components: {
    ContactUs,
  },
};
</script>
